<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex justify-space-between align-space-between w-100">
                <span class="secondary--text font-weight-bold">Video Library</span>
                <v-card flat v-if="!quotaLoading">
                    <v-card-text>
                        <v-progress-linear
                            rounded
                            height="6"
                            :value="usedQuota"
                            :color="usedQuota <= 90 ? 'primary' : 'error'"
                            class="mb-2"></v-progress-linear>
                        <div class="caption font-weight-medium d-flex align-center">
                            {{ `${usedQuota}% of ${toHours(videoLibraryQuota.limit)} hours` }}
                            used
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="!loading && videos.length === 0">
                <v-alert text prominent type="info">
                    <div class="d-flex align-center justify-space-between w-100">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">No videos found</span>
                            <span>
                                So far no videos have been uploaded to the Video Library.
                                <span v-if="isSpaceAdmin">
                                    You can upload videos to the Video Library by going to Files and uploading a video under Actions.
                                </span>
                            </span>
                        </div>
                        <v-btn
                            v-if="isSpaceAdmin"
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    fileArea: this.folderTypesLabels.WORKSPACE_FILES
                                }
                            }"
                            outlined
                            small
                            color="info">
                            Go to files
                        </v-btn>
                    </div>
                </v-alert>
            </div>
            <div v-else>
                <div class="d-flex flex-wrap align-center">
                    <v-spacer />
                    <v-text-field
                        v-model="search"
                        label="Search"
                        prepend-inner-icon="mdi-filter"
                        autofocus
                        hide-details
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        clearable
                        class="mr-2"></v-text-field>
                    <v-btn :loading="loading" @click="fetchVideos" icon>
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </div>
                <v-data-table
                    :show-select="videos.length > 0"
                    v-model="selected"
                    :search="search"
                    item-key="uri"
                    :headers="headers"
                    :loading="loading"
                    :items="videos"
                    class="pb-12">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <div class="d-flex align-center justify-end">
                            <vimeo-confirm-dialog :videos="selected" @dismissed="fetchVideos" :disabled="selected.length === 0">
                                <v-btn :disabled="selected.length === 0">
                                    <v-icon left>delete</v-icon>
                                    Delete videos
                                </v-btn>
                            </vimeo-confirm-dialog>
                            <v-spacer></v-spacer>
                            <v-data-footer
                                :pagination="pagination"
                                :options="options"
                                @update:options="updateOptions"
                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"></v-data-footer>
                        </div>
                    </template>
                    <template v-slot:item="{ item, index }">
                        <tr @mouseover="videoHover = index">
                            <td>
                                <v-checkbox multiple :value="item" v-model="selected" class="pa-0 ma-0" hide-details flat />
                            </td>
                            <td>
                                <div v-if="item.is_playable" class="py-2 preview" v-html="embedCode(item, 'responsive')"></div>
                                <div v-else class="py-2 preview">
                                    <div class="video-placeholder" :style="cssVars(item)">
                                        <div class="video-content">
                                            <span class="caption">processing...</span>
                                            <v-btn fab small color="primary" @click="fetchVideos">
                                                <v-icon>refresh</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="py-2">
                                    <div v-if="isSpaceArchived" class="secondary--text subtitle-1 font-weight-bold text-decoration-none">
                                        {{ item.name }}
                                    </div>
                                    <v-edit-dialog v-else :return-value.sync="item.name" @save="updateVideoName(item)">
                                        <div class="secondary--text subtitle-1 font-weight-bold text-decoration-none">
                                            {{ item.name }}
                                        </div>
                                        <template v-slot:input>
                                            <v-text-field v-model="item.name" label="Edit" single-line outlined></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                    <div class="subtitle-2" v-if="item.description">{{ item.description }}</div>
                                </div>
                            </td>
                            <td>
                                <span class="subtitle-2">
                                    {{ item.created_time | dateTimeToHuman }}
                                </span>
                            </td>
                            <td>
                                <span class="subtitle-2">
                                    {{ item.duration | durationToHuman }}
                                </span>
                            </td>
                            <td>
                                <div v-if="index === videoHover" class="d-flex justify-end">
                                    <vimeo-embed-code-btn :video="item"></vimeo-embed-code-btn>
                                    <vimeo-confirm-dialog :videos="[item]" @dismissed="fetchVideos">
                                        <v-btn class="ml-2 secondary--text" icon :disabled="loading" small>
                                            <v-icon small>delete</v-icon>
                                        </v-btn>
                                    </vimeo-confirm-dialog>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { fetchVimeoVideos } from '@/apis'
import { enumsData } from '@/mixins/enums'
import vimeo from '@/mixins/vimeo'
import { Duration } from 'luxon'

const VimeoEmbedCodeBtn = () => import('../components/VimeoEmbedCodeBtn.vue')
const VimeoConfirmDialog = () => import('../components/VimeoConfirmDialog.vue')

export default {
    name: 'VimdeoVideos',
    components: {
        VimeoEmbedCodeBtn,
        VimeoConfirmDialog
    },
    mixins: [enumsData, vimeo],
    data() {
        return {
            distributionReasons: {
                MATERIAL_SHARING: 'DISTRIBUTION',
                ASSIGNMENT: 'ASSIGNMENT'
            },
            headers: [
                { text: 'Preview', value: 'embed', filterable: false },
                { text: 'Name', value: 'name' },
                { text: 'Created', value: 'created_time' },
                { text: 'Duration', value: 'duration' },
                { text: 'Actions', value: 'actions', width: '300px', filterable: false, align: 'right' }
            ],
            selected: [],
            search: '',
            videos: [],
            loading: false,
            quotaLoading: false,
            videoHover: -1,
            videoLibraryQuota: null
        }
    },
    created() {
        this.fetchVideos()
        this.fetchQuota()
    },
    computed: {
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'isSpaceArchived']),
        ...mapGetters('instanceStore', ['isMasterInstance']),
        usedQuota() {
            return ((this.videoLibraryQuota.current_usage / this.videoLibraryQuota.limit) * 100).toFixed(2)
        }
    },
    methods: {
        cssVars(video) {
            return {
                '--padding': this.getRatio({ width: video.width, height: video.height }) + '%'
            }
        },
        async fetchVideos() {
            this.selected = []
            this.loading = true
            try {
                const { data } = await fetchVimeoVideos(this.$route.params.sid)
                this.videos = data
            } catch (error) {
                console.log(error)
            }
            this.loading = false
        },
        async fetchQuota() {
            this.quotaLoading = true
            try {
                const { data } = await this.$axios.get(`/spaces/${this.$route.params.sid}/vimeo_limit`)
                this.videoLibraryQuota = data
            } catch (error) {
                console.log(error)
            }
            this.quotaLoading = false
        },
        async updateVideoName(video) {
            try {
                await this.$axios.patch(`/spaces/${this.$route.params.sid}/edit_video`, {
                    uri: video.uri,
                    name: video.name
                })
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Video name successfully changed!`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
            } catch (error) {
                console.log('error:', error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Could not rename video, please try again later!`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
            }
        },
        toHours(seconds) {
            const dur = Duration.fromObject({ seconds })
            if (!dur.isValid) {
                return 'Invalid duration'
            }
            return dur.toFormat('h')
        }
    }
}
</script>

<style lang="scss" scoped>
.preview {
    width: 130px;
}

.video-placeholder {
    position: relative;
    background-color: var(--v-secondary-lighten5);
    padding-bottom: var(--padding);
    .video-content {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: absolute;
    }
}

::v-deep .v-data-table .v-data-footer {
    border-top: none;
}
</style>
