export default {
    methods: {
        getRatio({ width, height }) {
            return (height / width) * 100
        },
        embedCode(video, size) {
            const videoId = video.uri.split('/').pop()
            const ratio = this.getRatio({ width: video.width, height: video.height })
            const responsive = `<div style="padding:${ratio}% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/${videoId}?h=573c53b1b2&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>`
            const fixed = `<iframe src="https://player.vimeo.com/video/${videoId}?h=763119e73b&color=ffffff&title=0&byline=0&portrait=0&badge=0" width="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
            return size === 'fixed' ? fixed : responsive
        }
    }
}
